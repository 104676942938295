<template lang="pug">
  .template-filter-agency.d-flex.mt-2.mb-5.row
    .template-filter-agency-users(v-if="showTypeFilter" :class="filterColClass")
      multiselect(id="template-kind"
                  :options="templateKindOptions"
                  label="key"
                  track-by="value"
                  :placeholder="$t('templateManager.placeholders.templateKind')"
                  :multiple="false"
                  :show-labels="false"
                  :allow-empty="false"
                  v-model="templateKindComputed")
    .template-filter-agency-types.mb-2.mb-md-0(:class="filterColClass")
      multiselect(id="types"
                  :options="sortedTemplateTypes"
                  label="key"
                  track-by="value"
                  :placeholder="$t('templateManager.selectWidgetType')"
                  :multiple="false"
                  :show-labels="false"
                  :allow-empty="false"
                  v-model="selectedTypeComputed")
    .template-filter-agency-goals.mb-2.mb-md-0(:class="filterColClass")
       multiselect(id="goals"
                  :options="sortedTemplateGoals"
                  label="key"
                  track-by="value"
                  :placeholder="$t('templateManager.placeholders.goals')"
                  :multiple="true"
                  :show-labels="false"
                  :allow-empty="true"
                  v-model="selectedGoalsComputed")
        template(slot="selection" slot-scope="{ values, search, isOpen }")
          span.multiselect__single(v-if="values.length && !isOpen")
            strong {{values[0].key}}
            | {{ (values.length - 1) ? ` + ${values.length - 1} ${$t('more')}` : '' }}
    .template-filter-agency-categories.mb-2.mb-md-0(:class="filterColClass")
      multiselect(id="themes"
                  :options="sortedTemplateThemes"
                  label="key"
                  track-by="value"
                  :placeholder="$t('templateManager.placeholders.theme')"
                  :multiple="true"
                  :show-labels="false"
                  :allow-empty="true"
                  v-model="selectedThemesComputed")
        template(slot="selection" slot-scope="{ values, search, isOpen }")
          span.multiselect__single(v-if="values.length && !isOpen")
            strong {{values[0].key}}
            | {{ (values.length - 1) ? ` + ${values.length - 1} ${$t('more')}` : '' }}
    .template-filter-agency-categories.mb-2.mb-md-0(:class="filterColClass")
      multiselect(id="categories"
                  :options="sortedTemplateCategories"
                  label="key"
                  track-by="value"
                  :placeholder="$t('templateManager.placeholders.categories')"
                  :multiple="true"
                  :show-labels="false"
                  :allow-empty="true"
                  v-model="selectedCategoriesComputed")
        template(slot="selection" slot-scope="{ values, search, isOpen }")
          span.multiselect__single(v-if="values.length && !isOpen")
            strong {{values[0].key}}
            | {{ (values.length - 1) ? ` + ${values.length - 1} ${$t('more')}` : '' }}
    .template-filter-agency-users(:class="filterColClass")
      multiselect(id="users"
                  :options="users.map(u => ({ key: u.name, value: u._id }))"
                  label="key"
                  track-by="value"
                  :placeholder="$t('templateManager.placeholders.users')"
                  :multiple="true"
                  :show-labels="false"
                  :allow-empty="true"
                  v-model="selectedUsersComputed")
        template(slot="selection" slot-scope="{ values, search, isOpen }")
          span.multiselect__single(v-if="values.length && !isOpen")
            strong {{values[0].key}}
            | {{ (values.length - 1) ? ` + ${values.length - 1} ${$t('more')}` : '' }}
</template>

<script>
  import { mapGetters, mapState } from 'vuex';
  import { get as _get } from 'lodash-es';

  export default {
    props: {
      templateTypes: {
        type: Array,
        default: () => [],
      },
      templateGoals: {
        type: Array,
        default: () => [],
      },
      templateThemes: {
        type: Array,
        default: () => [],
      },
      templateCategories: {
        type: Array,
        default: () => [],
      },
      selectedType: { type: String },
      selectedThemes: { type: Array },
      selectedCategories: { type: Array },
      selectedGoals: { type: Array },
      selectedUsers: { type: Array },
      users: { type: Array },
      showType: Boolean,
      templateKind: { type: [String, null], default: null },
    },

    data() {
      return {
        categoryShowMore: false,
      };
    },

    computed: {
      ...mapState({
        features: (state) => _get(state, 'account.features', []),
      }),
      ...mapGetters(['databaseId']),
      templateKindOptions() {
        return [
          { key: this.$t('templateManager.filter.all'), value: 'all' },
          { key: this.$t('templateManager.filter.normalTemplates'), value: null },
          { key: this.$t('templateManager.filter.universalTemplates'), value: 'universal' },
        ];
      },
      sortedTemplateTypes() {
        const types = (this.templateTypes || [])
          .map((g) => ({
            key: this.$t(`${g.name}`),
            value: g.name,
          }))
          .sort((a, b) => a.key.localeCompare(b.key));

        types.unshift({ key: this.$t('all'), value: null });

        return types;
      },
      sortedTemplateGoals() {
        return (this.templateGoals || [])
          .map((g) => ({
            key: this.$t(`templateFilter.goals.${g.name}`),
            value: g.name,
          }))
          .sort((a, b) => a.key.localeCompare(b.key));
      },
      sortedTemplateThemes() {
        if (!this.templateThemes) {
          return [];
        }
        return this.templateThemes
          .map((e) => {
            return {
              key: e,
              value: e,
            };
          })
          .sort((a, b) => a.key.localeCompare(b.key));
      },
      sortedTemplateCategories() {
        return (this.templateCategories || [])
          .map((g) => ({
            key: this.$t(`templateFilter.categories.${g.name}`),
            value: g.name,
          }))
          .sort((a, b) => a.key.localeCompare(b.key));
      },
      selectedGoalsComputed: {
        get() {
          return this.selectedGoals.map((g) => ({
            key: this.$t(`templateFilter.goals.${g}`),
            value: g,
          }));
        },

        set(v) {
          this.$emit('update:selectedGoals', v && v.length ? v.map(({ value }) => value) : v);
        },
      },
      selectedThemesComputed: {
        get() {
          return this.selectedThemes.map((e) => ({
            key: e,
            value: e,
          }));
        },
        set(v) {
          this.$emit('update:selectedThemes', v && v.length ? v.map(({ value }) => value) : v);
        },
      },
      selectedCategoriesComputed: {
        get() {
          return this.selectedCategories.map((c) => ({
            key: this.$t(`templateFilter.categories.${c}`),
            value: c,
          }));
        },

        set(v) {
          this.$emit('update:selectedCategories', v && v.length ? v.map(({ value }) => value) : v);
        },
      },
      selectedTypeComputed: {
        get() {
          return this.selectedType
            ? { key: this.$t(this.selectedType), value: this.selectedType }
            : { key: this.$t('all'), value: null };
        },

        set(v) {
          this.$emit('update:selectedType', v.value);
        },
      },
      selectedUsersComputed: {
        get() {
          const selected = [];
          this.selectedUsers.forEach((sId) => {
            const user = this.users.find((u) => u._id === sId);
            if (user) {
              selected.push({
                key: user.name,
                value: user._id,
              });
            }
          });
          return selected;
        },
        set(v) {
          this.$emit('update:selectedUsers', v && v.length ? v.map(({ value }) => value) : v);
        },
      },
      showTypeFilter() {
        return this.showType && this.databaseId === 44;
      },
      filterColClass() {
        return {
          'col-sm-3': !this.showTypeFilter,
          'col-sm-2': this.showTypeFilter,
        };
      },
      templateKindComputed: {
        get() {
          const base = 'templateManager.filter.';
          let key;

          if (this.templateKind === 'universal') {
            key = this.$t(`${base}${this.templateKind}Templates`);
          } else if (this.templateKind === 'all') {
            key = this.$t(`${base}all`);
          } else {
            key = this.$t(`${base}normalTemplates`);
          }

          return {
            key,
            value: this.templateKind,
          };
        },
        set({ value }) {
          this.$emit('update:templateKind', value);
        },
      },
    },

    methods: {
      setTemplateType(type) {
        this.$emit('update:selectedType', type);
      },
    },
  };
</script>
