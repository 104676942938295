<template lang="pug">
.template-thumbnail-box.brand-themes-template-box._lr-hide(
  data-private
  ref="parent"
  @click="$emit('click')"
  :class="{ 'fallback-shown': !ssr, 'universal-template': template.universal, embedded: isEmbedded }"
  :id="boxId"
  :data-template-name="template.name"
)
  .template-thumbnail-overlay.brand-themes-template-box-overlay
  .template-thumbnail-iframe(v-if="ssr" :style="frameStyle" ref="lazyFrame" :id="frameId")
  template(v-else-if="!ssr")
    slot(name="fallback")
      template-image(:template="template")
  om-chip.om-chip-position.om-chip-opacity(
    v-if="ssr && !loading && isEmbedded"
    color="gray"
    small
    value="Embedded"
  )
    slot Embedded
  spinner-loader.template-thumbnail-loading(v-if="ssr && loading")
</template>
<script>
  import TemplateImage from '@/components/Template/TemplateImage';
  import SpinnerLoader from '@/components/Elements/SpinnerLoader';
  import previewFrameMixin from '@/mixins/previewFrame';
  import paletteMixin from '@/mixins/palette';
  import { debounce } from 'lodash-es';

  export default {
    components: { TemplateImage, SpinnerLoader },
    mixins: [previewFrameMixin, paletteMixin],
    props: {
      template: { type: Object, required: true },
      color: { type: String, default: null },
      customMultiplier: {
        type: Number,
        default: null,
      },
      idPostfix: { type: String, default: null },
    },
    computed: {
      appendablePostfix() {
        return this.idPostfix ? `-${this.idPostfix}` : '';
      },
      boxId() {
        return `fallback-shown-${this.template._id}${this.appendablePostfix}`;
      },
      frameId() {
        return `template-preview-iframe-${this.template._id}${this.appendablePostfix}`;
      },
    },
    watch: {
      template() {
        if (this.visible) {
          this.updatePalette(this.color);
          this.setFrameContent().then(() => {
            this.visible = true;
          });
        }
        this.zIndex = '-999999';
        this.visible = false;
        this.shown = false;
      },
    },

    created() {
      this.changeColor = debounce((n, o) => {
        if (n !== o) {
          if (this.visible) {
            this.updatePalette(n);
            this.setFrameContent().then(() => {
              this.visible = true;
            });
          }
          this.zIndex = '-999999';
          this.visible = false;
          this.shown = false;
        }
      }, 300);
      if (this.isUniversal) {
        this.$watch('color', this.changeColor);
      }
    },
    methods: {
      getId() {
        return this.template._id;
      },
    },
  };
</script>

<style lang="sass">
  .brand-themes-details-templates
    .col-md-6
      @media screen and (min-width: 1921px)
        flex: 0 0 25%
        max-width: 25%
  #fallback-shown-606ec99bac7661001cde4c26
    .brand-lazy-template-image
      width: 14.5vw
      height: 4vw
      bottom: 1.25rem
      left: 1.25rem
      background-size: contain !important
      @media screen and(max-width: 767px)
        width: 33vw
        height: 15vw
  .brand-themes-template-box
    position: relative
    background: url('~@/assets/admin/svg/template-background.svg') no-repeat center center / contain #E3E5E8
    display: flex
    align-items: center
    justify-content: center
    border-radius: 8px
    border: 1px solid #f7f7f8
    overflow: hidden
    padding: 15px
    > .brand-themes-template-box
      background: transparent
      border: 0

    &:nth-child(2)
      margin-right: 0

    &-overlay
      position: absolute
      left: 0
      top: 0
      width: 100%
      height: 100%
      z-index: 0
      border-radius: 0

    img,
    svg
      position: relative
      width: 80%
      height: 80%
      object-fit: contain
    &.nanobar-template
      align-items: flex-start
      padding: 0
      img,
      svg
        height: auto
        width: 100%
    &.sidebar-template
      align-items: flex-end
      justify-content: flex-start
      img,
      svg
        width: auto
        height: auto
        max-width: 50%
        max-height: 65%
      &.fullheight-template
        img,
        svg
          height: 100%
      &.centered-bottom-template
        svg
          height: 33%
          margin-left: auto
          margin-right: auto
    &.embedded
      background: unset
    &.fullscreen-template
      padding: 0
      img, svg
        width: 100%
        height: 100%
        object-fit: cover
    &.has-wheel
      svg
        margin-left: -5vw
    &.embedded-template
      padding: 0
      img, svg
        height: 100%
        width: 100%
        object-fit: cover


  .template-thumbnail
    &-box
      width: 100%
      .om-overlay-center
        font-size: 16px !important
      .om-embedded
        .om-outer-canvas
          display: flex
          align-items: center
          .om-canvas
            height: max-content !important
            width: 100%

    &-loading
      transition: .3s
      margin: auto
      position: absolute
      left: 0
      right: 0
      top: 0
      bottom: 0
    &-iframe
      width: 100%
      border: none
      display: block
      position: absolute
      left: 0
      right: 0
      margin: auto
      top: 0
      transform-origin: left top
      pointer-events: none
      .om-mock-body
        padding: 0 4rem
      .om-mock-col
        position: relative
      .om-mock-col .om-holder.om-holder-fixed
        position: static
      .widget-template-card-mobile-click-top &
        min-height: 170px
        max-height: 295px
      .om-element[type="OmScratchCard"]
        height: 24.25em !important
      div
        pointer-events: none
      .layout-4-col
        .om-holder-fixed
          position: unset
  .om-chip-position
    z-index: 1
    position: absolute
    bottom: 0
    right: 0
  .om-element[type="OmScratchCard"]
    #template-preview-iframe-5d822ab0305c6e0012747f46 &
      max-height: 17.5em
    #template-preview-iframe-5d42b8fcc36f440011589e3a &
      max-height: 19.75em
    #template-preview-iframe-5dd7d8e903e49a0011da6968 &
      max-height: 20rem
    #template-preview-iframe-5cefd6721258f30010cfbb97 &,
    #template-preview-iframe-5cee8e1ea8ee3e0010889b86 &
      max-height: 22.5rem
</style>
<style lang="sass" src="@/editor/sass/mock_site.sass" />
