<template lang="pug">
.template-prev-holder(
  :class="{ 'template-prev-holder-mobile': device === 'mobile', 'template-prev-holder-small': small, 'add-bg-color': small && !isEmbedded }"
)
  img.website-mockup(
    v-if="needWebsiteMockup"
    :src="require('@/assets/admin/svg/website-mockup.svg')"
  )
  .template-prev(
    :class="{ 'template-prev-mobile': device === 'mobile', 'template-prev-wider-lg': widerLgClass, 'template-prev-wider-xl': widerXlClass }"
  )
    loading-logo.loading-logo(ref="loadingLogo")
    div(
      :class="{ 'template-prev-mobile-frame': device === 'mobile', 'template-prev-embedded': isEmbedded, 'has-wheel': hasLuckyWheel }"
    )
      iframe(key="previewFrame" ref="iframe")
</template>

<script>
  import WebFont from 'webfontloader';
  import axios from 'axios';
  import { pageInitializer } from '@/editor/services/initHelper';
  import paletteMixin from '@/mixins/palette';
  import runtimeConfig from '@/config/runtime';
  import { calculatePageHeight } from '@/utils/template';
  import ssrMixin from '@/mixins/ssr';
  import ssrParamsMixin from '@/mixins/ssrParams';
  import { getAccountIdFromCookie, getEmbeddedTemplateHTML, getNormalTemplateHTML } from '@/util';

  const frontendUrl = runtimeConfig.VUE_APP_FRONTEND;
  const cdnUrl = runtimeConfig.VUE_APP_CDN_URL;

  export default {
    mixins: [paletteMixin, ssrMixin, ssrParamsMixin],
    props: {
      device: {
        type: String,
        default: 'desktop',
      },
      color: {
        type: String,
      },
      template: {
        type: Object,
        default: () => null,
      },
      small: {
        type: Boolean,
        default: false,
      },
      selectedPageUid: {
        type: String,
        default: null,
      },
      themeKit: {
        type: Object,
        default: () => null,
      },
      load: {
        type: Boolean,
        default: true,
      },
      isAdmin: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        lastSelectedPage: null,
        teaserPreview: false,
        teaserElement: null,
        colorChanged: false,
        loading: true,
        initialized: false,
        templateHTML: '',
        parsedTemplate: null,
        pages: [],
        templatePages: [],
        pageStyles: [],
        fonts: [],
        scope: '#om-campaign-0',
        styleElement: null,
      };
    },
    computed: {
      widerLgClass() {
        return (
          this.device !== 'mobile' &&
          !this.small &&
          !this.isPopup &&
          !this.isSidebar &&
          this.isEmbedded
        );
      },
      widerXlClass() {
        return (
          this.device !== 'mobile' &&
          !this.isTeaser &&
          !this.small &&
          !this.isEmbedded &&
          !this.isSidebar &&
          this.isPopup
        );
      },
      isTeaser() {
        return (
          this.pages.find((page) => page.uid === this.selectedPageUid)?.isTeaser ||
          this.selectedPageUid === 'tab' ||
          false
        );
      },
      needWebsiteMockup() {
        return !(
          (this.isSidebar || this.isEmbedded || this.isPopup || this.isInterstitial) &&
          this.device === 'mobile' &&
          !this.isTeaser
        );
      },
      hideOverlay() {
        return (
          (this.isSidebar && this.device === 'desktop') || this.isEmbedded || this.teaserPreview
        );
      },
      hasLuckyWheel() {
        return !!this.parsedTemplate?.elements.find((element) => element.type === 'OmLuckyWheel');
      },
      isInterstitial() {
        return this.template && this.template.type === 'interstitial';
      },
      isPopup() {
        return this.template && this.template.type === 'popup';
      },
      isSidebar() {
        return this.template && this.template.type === 'sidebar';
      },
      isEmbedded() {
        return this.template && this.template.type === 'embedded';
      },
      isUniversal() {
        return this.template && this.template.universal;
      },
      normalPages() {
        return this.pages.filter((p) => !p.isTeaser);
      },
      teaserPage() {
        return this.pages.find((page) => page.isTeaser);
      },
      ssrUrl() {
        const { VUE_APP_SSR_CDN_URL: ssrUrl } = runtimeConfig;
        if (ssrUrl) {
          return `${ssrUrl}/template-content/${this.template._id}`;
        }
        return null;
      },
    },
    watch: {
      template: {
        async handler(v) {
          if (!v) return;
          if (v.hasOwnProperty('template')) {
            await this.init(true);
          }
          this.colorizeUniversal();
        },
        deep: true,
      },
      color: {
        handler(current, previous) {
          if (!this.template.universal) return;
          const iframe = this.iframeEl();
          if (!iframe) return;
          this.showLoading(iframe);
          this.templateHTML = this.replaceColors(this.templateHTML, current);
          this.initIframe();
          if (this.lastSelectedPage) this.selectPage(this.lastSelectedPage);
          this.colorChanged = current !== previous;
        },
      },
      selectedPageUid(v) {
        if (v) {
          this.selectByUID(v);
          this.updateSelectedPageIndex(v);
        }
      },
      async load(v) {
        if (v) await this.init();
      },
    },
    methods: {
      colorizeUniversal() {
        if (this.template.universal) this.colorMagic(this.color);
      },
      updateSelectedPageIndex(v) {
        const pageIndex = this.templatePages.findIndex((page) => page.id === v);
        if (pageIndex === -1) return;

        this.selectPage(pageIndex);
      },
      selectPage(selectedIndex) {
        this.lastSelectedPage = selectedIndex;
        if (typeof selectedIndex === 'string') {
          this.selectByUID(selectedIndex);
        } else {
          this.teaserPreview = false;
          if (this.teaserElement) this.teaserElement.style.display = 'none';
          this.setOverlayDisplay('');

          this.templatePages.forEach((p, pageIndex) => {
            if (!this.isEmbedded) {
              if (pageIndex === selectedIndex) {
                p.style.display = '';
                p.classList.remove('om-canvas-hidden');
              } else {
                p.style.display = 'none';
              }
            } else if (pageIndex === selectedIndex) {
              p.classList.remove('om-canvas-hidden');
            } else {
              p.classList.add('om-canvas-hidden');
            }
          });
        }

        this.selectedPageIndex = selectedIndex;
      },
      getPageHeight(pageUid) {
        const pageIndex = this.templatePages.findIndex((page) => page.id === pageUid);
        if (pageIndex < 0) return null;

        const page = this.templatePages[pageIndex];
        const pageStyle = this.pageStyles[pageIndex];

        if (this.isEmbedded) {
          return calculatePageHeight(page, pageStyle);
        }

        return calculatePageHeight(page, pageStyle, false);
      },
      async init(force = false) {
        if (!this.template) return;
        if (!this.initialized || force) {
          this.loading = true;

          await this.buildTemplateHTML();
          this.setScope();
          this.colorizeUniversal();
          setTimeout(() => {
            this.loading = false;
            this.$emit('loadThumbnails');
          }, 800);
        }
      },
      iframeEl() {
        return this.$refs.iframe;
      },
      showLoading(iframe) {
        this.switchLoadingVisibility(iframe);
      },
      hideLoading(iframe) {
        setTimeout(() => {
          this.switchLoadingVisibility(iframe, false);
        }, 300);
      },
      switchLoadingVisibility(iframe, show = true) {
        this.$nextTick(() => {
          if (this.$refs.loadingLogo) {
            this.$refs.loadingLogo.$el.style.visibility = show ? 'visible' : 'hidden';
          }
          iframe.style.visibility = show ? 'hidden' : 'visible';
        });
      },

      setScope() {
        if (this.isModalsWithSsr) {
          this.scope = `#om-campaign-${this.template._id}`;
        }
      },
      async loadFromSsr() {
        let content = null;
        if (!this.template.hasOwnProperty('template')) {
          console.error('Template has no data for loadFromSsr');
          return;
        }
        if (this.isModalsWithSsr) {
          let resp;
          const params = await this.ssrQueryParams();
          if (this.isAdmin) {
            params.isAdmin = 1;
          }
          if (this.themeKit) {
            const { data: response } = await axios.get(this.ssrUrl, { params });
            resp = response;
          } else {
            const { data: response } = await axios.get(this.ssrUrl, { params });
            resp = response;
          }
          if (resp) content = resp;
        }
        return content;
      },
      async getTemplateContent() {
        try {
          const content = await this.loadFromSsr();
          if (content) return content;
        } catch (e) {
          console.error('Content load error', this.template._id);
        }
        try {
          const { data: html } = await axios.get(this.template.contentUrl);
          return html;
        } catch (e) {
          console.error('HTML load error', this.template.contentUrl);
        }
      },
      decodeHtml(str) {
        const txt = document.createElement('textarea');
        txt.innerHTML = str;
        return txt.value;
      },
      async parseAssets(html) {
        if (!this.template.hasOwnProperty('template')) {
          console.error('Template has no data for parseAssets');
          return false;
        }
        let assets;
        if (html.includes('data-custom-fonts')) {
          assets = /data-fonts="(.*)" data-custom-fonts/.exec(html);
        } else {
          assets = /data-fonts="(.*)" data-images/.exec(html);
        }
        if (assets && assets.length === 2) {
          try {
            this.fonts = await JSON.parse(this.decodeHtml(assets[1]));
          } catch (e) {
            console.log('Error occured while assets parsing');
            this.fonts = [];
          }
        }
      },
      async buildTemplateHTML() {
        const html = await this.getTemplateContent();
        await this.parseAssets(html);
        const { hideOverlay, isEmbedded } = this;
        if (this.isEmbedded) {
          this.templateHTML = getEmbeddedTemplateHTML(html, {
            hideOverlay,
            isEmbedded,
            frontendUrl,
            device: this.device,
          });
        } else {
          this.templateHTML = getNormalTemplateHTML(html, {
            hideOverlay,
            isEmbedded,
            frontendUrl,
          });
        }
        this.initIframe();
      },
      replaceColors(content, color) {
        try {
          if (this.themeKit && !color) {
            color = this.themeKit?.colors?.mainColor;
          }
          const palette = this.getPaletteColors(color);
          // This will collect data image SVGs
          const regex = /data:image\/svg\+xml,(.*?svg%3E)/gi;
          let result = content;
          let matches = regex.exec(content);
          while (matches) {
            if (!matches[1]) continue;
            let svg = decodeURIComponent(matches[1]);
            // Match the colors
            const colorRegex = /\.om_color_([1-5])[\s]?\{.*?:((currentColor|rgb|rgba|#).*?);.*?}/gi;
            let colorMatches = colorRegex.exec(svg);

            while (colorMatches) {
              const cIndex = parseInt(colorMatches[1] || '0', 10);
              const color = palette[cIndex - 1];
              svg = svg.replace(colorMatches[0], colorMatches[0].replace(colorMatches[2], color));
              colorMatches = colorRegex.exec(svg);
            }
            result = result.replace(matches[1], encodeURIComponent(svg));
            matches = regex.exec(content);
          }

          return result;
        } catch (e) {
          console.log(e);
          return content;
        }
      },
      resizeScratchCard() {
        const iframe = this.iframeEl();
        if (!iframe) return;
        const iframedoc = iframe.contentDocument || iframe.contentWindow.document;
        const scratchWrapper = iframedoc.querySelectorAll('.om-scratch-wrapper');
        if (scratchWrapper && scratchWrapper.length > 0) {
          const originalSize = 350;
          scratchWrapper[0].style.width = `${originalSize}px`;
          scratchWrapper[0].parentNode.style.height = `${originalSize}px`;
          setTimeout(() => {
            const { width } = scratchWrapper[0].getBoundingClientRect();
            scratchWrapper[0].style.width = `${width}px`;
            scratchWrapper[0].parentNode.style.height = `${width}px`;
          }, 300);
        }
      },
      showOldTab() {
        this.$nextTick(() => {
          this.setOverlayDisplay('none');
          const iframe = this.iframeEl();
          if (!iframe) return;
          const iframedoc = iframe.contentDocument || iframe.contentWindow.document;
          const tabWrapper = iframedoc.querySelector('.om-tab-wrapper');
          if (tabWrapper) tabWrapper.style.display = null;
        });
      },
      selectByUID(id) {
        if (id === 'tab') {
          this.showOldTab();
          return;
        }
        this.templatePages.forEach((p) => {
          if (p.id === id) {
            this.showPage(p);
          } else {
            this.hidePage(p);
          }
        });
      },

      showPage(page) {
        if (this.teaserPage && page.id === this.teaserPage.uid) {
          this.$nextTick(() => {
            this.teaserElement.style.display = '';
            this.setOverlayDisplay('none');
          });
        }

        if (!this.isEmbedded) {
          page.style.display = '';
          page.classList.remove('om-canvas-hidden');
        } else {
          page.classList.remove('om-canvas-hidden');
        }
      },

      hidePage(page) {
        if (this.teaserPage && page.id === this.teaserPage.uid) {
          this.teaserElement.style.display = 'none';
          this.setOverlayDisplay('');
        }

        if (!this.isEmbedded) {
          page.style.display = 'none';
        } else {
          page.classList.add('om-canvas-hidden');
        }
      },

      setOverlayDisplay(display) {
        const iframe = this.iframeEl();
        const iframedoc = iframe.contentDocument || iframe.contentWindow.document;
        const overlayElement = iframedoc.querySelector('.om-overlay');
        if (overlayElement && overlayElement.style) overlayElement.style.display = display;
      },
      colorMagic(color) {
        if (!this.template.universal) return;
        const iframe = this.iframeEl();
        if (!iframe) return;
        this.templateHTML = this.replaceColors(this.templateHTML, color);
        this.initIframe();
      },

      initIframe() {
        this.parsedTemplate = JSON.parse(this.template.template);
        this.pages = [];

        if (this.parsedTemplate.elements) {
          this.pages = this.parsedTemplate.elements.filter((el) => el.type === 'OmPage');
          this.selectedPageIndex = this.templatePages.findIndex((page) => !page.isTeaser);
        } else if (this.parsedTemplate.pages) {
          this.pages = this.parsedTemplate.pages;
        }

        this.pages.forEach(
          (p, index) => pageInitializer(p, index, this.pages.length),
          this.parsedTemplate,
        );

        const iframe = this.iframeEl();
        const iframedoc = iframe.contentDocument || iframe.contentWindow.document;
        this.templateHTML = this.replaceColors(this.templateHTML, this.color);
        iframedoc.body.innerHTML = this.templateHTML;
        this.resizeScratchCard();

        setTimeout(() => {
          this.loadTemplateFonts(iframe);
        }, 0);

        this.templatePages = Array.from(iframedoc.querySelectorAll('[id^=pge]'));
        this.teaserElement = iframedoc.querySelector('[class^="om-tab-wrapper"]');
        this.pageStyles = this.templatePages.map(({ style, desktop }) => style || desktop);
        if (this.color) {
          this.styleElement = iframedoc.querySelector('#custom-colors');
          this.updatePalette(this.color);
        }

        if (this.selectedPageUid) {
          this.selectByUID(this.selectedPageUid);
          this.selectedPageIndex = this.pages.findIndex(
            (page) => page.uid === this.selectedPageUid,
          );
        }

        this.hideLoading(iframe);
        this.initialized = true;
      },
      getTemplateFonts(body) {
        let google = [];
        let custom = [];

        try {
          const assets = body.querySelector('.om-asset-helper');
          const gFontsRaw = assets?.getAttribute('data-fonts') || '[]';
          const cFontsRaw = assets?.getAttribute('data-custom-fonts') || '[]';
          google = JSON.parse(gFontsRaw) || [];
          custom = JSON.parse(cFontsRaw) || [];
        } catch (e) {
          console.log('cannot parse template fonts', e.message);
        }

        return { google, custom };
      },
      loadTemplateFonts(iframe) {
        const document = iframe.contentDocument || iframe.contentWindow.document;
        const { google, custom } = this.getTemplateFonts(document.body);

        if (!google.length && !custom.length) return;

        const config = {
          google: { families: google },
          context: iframe.contentWindow,
        };

        if (custom.length) {
          config.custom = {
            families: custom,
            urls: custom.map(
              (font) => `${cdnUrl}/customFonts/${getAccountIdFromCookie()}/${font}/${font}.css`,
            ),
          };
        }

        WebFont.load(config);
      },
    },
  };
</script>
<style lang="sass">
  @import '@/sass/variables/variables'
  @import "@/sass/helpers/media-breakpoint"

  .template-prev
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    border: 0
    overflow: hidden
    &-wider
      &-lg
        @media screen and (min-width: 1281px)
          transform: scale(1.2)
          .brand-loading-transition-icon
            transform: scale(.85)
        @media screen and (max-width: 1080px) and (min-width: 845px)
          transform: scale(1.2)
          .brand-loading-transition-icon
            transform: scale(.85)
      &-xl
        @media screen and (min-width: 1281px)
          transform: scale(1.5)
          .brand-loading-transition-icon
            transform: scale(.65)
        @media screen and (max-width: 1080px) and (min-width: 845px)
          transform: scale(1.2)
          .brand-loading-transition-icon
            transform: scale(.85)
    &-container
      overflow: hidden
      display: flex
      flex-direction: column
      border-radius: 4px
    &-holder
      height: 100%
      width: 100%
      flex-grow: 1
      overflow: hidden
      position: relative
      display: flex
      align-items: center
      border-radius: 8px 8px 8px 8px
      border: 1px solid $om-gray-300
      &.add-bg-color
        background: #E3E5E8
      &-mobile
        padding-top: 0
      img.website-mockup
        position: absolute
        left: 0
        top: 0
        right: 0
        bottom: 0
        padding: 1.5rem
        object-fit: cover
        height: 100%
        width: 100%
      &-small
        position: relative
        overflow: visible
        width: 120px
        height: 67.5px
        pointer-events: none
        border-radius: 4px
        padding-top: 0
        .loading-logo
          scale: .3

        iframe
          position: absolute!important
          left: 0 !important
          top: 0 !important
          transform: scale(0.1) !important
          transform-origin: left top !important
          width: 1200px !important
          height: 675px !important
        .has-wheel
          iframe
            transform: scale(0.16) !important
            height: 415px !important

        &.template-prev-holder-mobile
          .om-mock-col
            iframe
              width: 238px !important
          iframe
            width: 250px !important
            height: 560px !important
            top: 4px !important
            left: 2px!important

        img.website-mockup
          height: 100%
          padding: 0.25rem

    .loading-logo
      position: absolute
      top: 0
      z-index: 2
      width: 100%
      height: 100%
      // left: 50%
      // transform: translateX(-50%)

    iframe
      visibility: hidden
      width: 100%
      height: 100%
      border: 0
      background: transparent
      position: absolute
      top: 0
      left: 0

      &.embedded-preview-iframe
        position: static

      body
        margin: 0

    &-mobile
      transform-origin: center
      position: relative
      border: none
      overflow: hidden
      .template-prev-holder-small &
        width: 120px
        height: 67.5px
        transform: scale(1) translate(0)
        border-radius: 4px
        .template-prev-holder-small &
          width: 32px
          border-radius: 4px
          height: 68px
          top: 2px
          padding: 0
  @media screen and (max-width: 1599px)
    .template-prev-holder-mobile:not(.template-prev-holder-small)
      overflow-y: auto !important
    .template-prev-holder:not(.template-prev-holder-small) .template-prev-mobile
      margin-top: 0
  .template-prev-holder-small
    .om-mock-body
      overflow-y: hidden
</style>
